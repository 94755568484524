import React from "react";

import GetPosts from "./getPosts";
import MakePost from "./makePost";

import { Container, Row, Col } from "react-bootstrap";

const Posts = () => {
  return (
    <>
      <Container className="mx-auto my-3">
        <Row className="mb-4">
          <Col>
            <MakePost />
          </Col>
        </Row>
        <Row>
          <Col>
            <GetPosts />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Posts;
