import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Container } from "react-bootstrap";

const GetPosts = () => {
  const [userPosts, setUserPosts] = useState([]);
  const [otherPosts, setOtherPosts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(
          "https://workers_api_python.robertnimmo26.workers.dev/posts"
        );

        const temp_user_post = [];
        const temp_other_post = [];

        const posts = Array.from(res.data);

        posts.map((post) => {
          if (post.username === "livetheadventure") {
            temp_user_post.push(post);
          } else {
            temp_other_post.push(post);
          }
        });

        setUserPosts(temp_user_post);
        setOtherPosts(temp_other_post);

        console.log(temp_user_post);
        console.log(temp_other_post);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="my-auto">
      <h3>Your feed</h3>
      <Card className="my-4">
        <Container className="mx-auto my-3">
          <Card.Title>Your posts</Card.Title>
          {userPosts.length === 0 ? (
            <Card.Body>You have no posts yet. Get posting!</Card.Body>
          ) : (
            userPosts.map((post) => (
              <div>
                <Card className="mx-3 my-3">
                  <Card.Subtitle className="mx-2 mt-2 text-muted">
                    {post.title}
                  </Card.Subtitle>
                  <Card.Body>{post.content}</Card.Body>
                  <Card.Footer>{post.username}</Card.Footer>
                </Card>
              </div>
            ))
          )}
        </Container>
      </Card>

      <Card className="my-4">
        <Container className="mx-auto my-3">
          <Card.Title>Other posts</Card.Title>
          {otherPosts.length === 0 ? (
            <Card.Body>
              No one else has posted. Share Socialflare with your friends and
              family so there is more content for everyone.
            </Card.Body>
          ) : (
            otherPosts.map((post) => (
              <div>
                <Card className="mx-3 my-3">
                  <Card.Subtitle className="mx-2 mt-2 text-muted">
                    {post.title}
                  </Card.Subtitle>
                  <Card.Body>{post.content}</Card.Body>
                  <Card.Footer>{post.username}</Card.Footer>
                </Card>
              </div>
            ))
          )}
        </Container>
      </Card>
    </div>
  );
};

export default GetPosts;
