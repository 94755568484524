import NavBar from "./components/navbar";
import Posts from "./components/posts";

// import './App.css';

const App = () => (
  <>
    <NavBar />
    <Posts />
  </>
);

export default App;
