import { React, Component } from "react";

import { Card, Form, Button } from "react-bootstrap";

class MakePost extends Component {
  handleSubmit = (event) => {
    //event.preventDefault();
    const form = event.currentTarget;

    const title = form.makePostFormTitle.value;
    const content = form.makePostFormContent.value;

    const jsonObject = {
      title: title,
      username: "livetheadventure",
      content: content,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: JSON.stringify(jsonObject),
    };
    fetch(
      "https://workers_api_python.robertnimmo26.workers.dev/posts",
      requestOptions
    );
  };

  render() {
    return (
      <>
        <Card>
          <Card.Body>
            <Card.Title>Write a post!</Card.Title>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3" controlId="makePostFormTitle">
                <Form.Control
                  type="text"
                  placeholder="Whats your post about?"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="makePostFormContent">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Tell us more..."
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Share to the world
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default MakePost;
